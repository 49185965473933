import React from "react";
import Universties from "../components/Home/Universties";
import { Helmet } from 'react-helmet-async';

const UniversitesPage = () => {
  return (
    <div>
        <Helmet>
        <title>Top International Universities for Students | Foreign Institutions</title>
        <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <meta name="description" content="Discover top universities for international students at Edulley. Explore foreign institutions that offer quality education and diverse cultural experiences." />
        <link rel="canonical" href="https://edulley.com/institutions" />
      </Helmet>
      <Universties />
    </div>
  );
};

export default UniversitesPage;
