import React, { useEffect, useState, createContext } from "react";
import { Routes, Route, Navigate, useLocation, useNavigationType } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import Footer from "./components/Footer";
import Courses from "./Pages/Courses";
import CourseList from "./Pages/CourseList";
import CompareCourse from "./Pages/CompareCourse";
import UniversitesPage from "./Pages/UniversitesPage";
import Scholarship from "./Pages/Scholarship";
import ExamIelts from "./Pages/exams/ExamIelts";
import TopicIlets from "./Pages/exams/TopicIlets";
import IletsCue from "./Pages/exams/IletsCue";
import IletsEssay from "./Pages/exams/IletsEssay";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import InstitutionDetail from "./Pages/InstitutionDetail";
import FAQ from "./components/Home/FAQ";
import TermCondition from "./Pages/TermCondition";
import PrivacyPolicy from "./Pages/privacy-policy";
import ContactUs from "./Pages/contact-us";
import RefundPolicy from "./Pages/refund-policy";
import ProfileForm from "./components/Home/Profile/profile-form";
import LoginModal from "./components/auth/login";
import { Toaster } from "react-hot-toast";
import './App.css';
import SOP from "./Pages/study-abroad/sop";
import LOR from "./Pages/study-abroad/lor";
import CourseDetails from "./components/courseDetails";
import CarrerPath from "./Pages/Carrer/carrer-path";
import CarrerPathDetails from "./Pages/Carrer/carrer-details";
import ChatSupport from "./Pages/chatSupport";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NotFound from "./Pages/notFound";

export const ModalContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginModalType, setLoginModalType] = useState('signup');
  const location = useLocation();
  const action = useNavigationType();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("_u"));
    const loggedIn = !!user?.token;
    setIsLoggedIn(loggedIn);
  }, []);

  const RequireAuth = ({ children }) => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      setLoginModalType('signup');
      return <Navigate to="/" replace />;
    }
    return children;
  };

  return (
    <ModalContext.Provider value={{ showLoginModal, setShowLoginModal, loginModalType, setLoginModalType }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses-list" element={<CourseList />} />
        <Route path="/compare" element={<CompareCourse />} />
        <Route path="/institutions" element={<UniversitesPage />} />
        <Route path="/scholarship" element={<Scholarship />} />
        <Route path="/exam-ielts" element={<ExamIelts />} />
        <Route path="/ielts-topic" element={<TopicIlets />} />
        <Route path="/ielts-cue-card" element={<IletsCue />} />
        <Route path="/ielts-essay" element={<IletsEssay />} />
        <Route path="/sop" element={<SOP />} />
        <Route path="/lor" element={<LOR />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:heading" element={<BlogDetail />} />
        <Route path="/blog-details" element={<BlogDetail />} />
        <Route path="/institution-details" element={<InstitutionDetail />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-and-condition" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/profile" element={<RequireAuth><ProfileForm /></RequireAuth>} />
        <Route path="/career-path" element={<CarrerPath />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/carrer-details" element={<CarrerPathDetails />} />
        <Route path="/chat-support" element={<ChatSupport />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            backgroundColor: '#FF5573',
            color: '#FFFFFF',
          },
        }}
      />
      {showLoginModal && !isLoggedIn && (
        <LoginModal
          isOpen={showLoginModal}
          onClose={() => setShowLoginModal(false)}
          initialTab={loginModalType}
        />
      )}
      <Footer />
    </ModalContext.Provider>
  );
}

export default App;